import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import HeaderFeaturedImage from "../components/common/HeaderFeaturedImage"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import LokalerMovie from "../components/lokaler/LokalerMovie"
import MeetingEventMenu from "../components/lokaler/MeetingEventMenu"
import Button from "../components/common/Button"
import SEO from "../components/seo"

export default function MotenEventPage() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 1162 }) {
        id
        title
        content
        seo {
          metaDesc
        }

        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const page = data.wpPage
  const contentTrimmed = page.content
    ? page.content.substring(0, 150).replace(/<[^>]*>?/gm, "")
    : ""
  const metaDescription = page.seo.metaDesc || contentTrimmed
  const { title, featuredImage, content } = page

  return (
    <Layout lang="sv">
      <SEO title={page.title} description={metaDescription} />
      <div className="">
        <LokalerMovie text={title} />
      </div>
      <div>
        <MeetingEventMenu lang="sv" />
        <div className="page-inner flex flex-col gap-4 lg:gap-8 ">
          <div className="max-w-4xl mx-auto flex flex-col gap-4 lg:gap-8">
            <div
              className="wp-content "
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div>
              <Button
                text="Kontakta oss"
                size="lg"
                url="/infor-ditt-besok/kontakt/"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
